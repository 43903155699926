import React from 'react';
import PropTypes from 'prop-types';
import './Footer.css';

const Footer = () => (
    <div className="footer-basic">
        <footer>
            <p className="copyright">{process.env.REACT_APP_OWNER} © {new Date().getFullYear()}</p>
        </footer>
    </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
