import React from 'react';
import './AboutComponent.css';

function AboutComponent() {
    return (
        <div className="AboutComponent">
            <div className="about-container">
                <div className="container">
                    <div className="col-sm-12 row">
                        <div className="col-sm-6 about-div-image">
                            <img className="about-image" src="/img/avatar-k.JPG" alt="avatar"/>
                        </div>
                        <div className="col-sm-6 about-div-content">
                            <h2>About Kav</h2>
                            <p>Hi, I'm {process.env.REACT_APP_OWNER.split(' ')[0]}</p>
                            <p>I'm a technical program manager living and working in GTA, ON, Canada. Currently, I'm
                                leading the program and product management department at a software agency in Downtown
                                Toronto. </p>
                            <p>Apart from my professional commitments, I spent most of my time on writing on my blog,
                                documenting my experience in video format, and playing video games and my guitar. </p>
                            <h6>Toss me a line</h6>
                            <a href="mailto:hello@narathota.com">hello@narathota.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

AboutComponent.propTypes = {};

AboutComponent.defaultProps = {};

export default AboutComponent;
