import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Link, Routes, Navigate} from 'react-router-dom';

import './App.css';
import NavBar from "./components/NavBar/NavBar";

import CoverLetter from "./page/CoverLetter";
import Page404 from "./page/Page404";
import Feed from "./page/Feed";
import LandingSection from "./components/LandingSection/LandingSection";
import About from "./page/About";
import Footer from "./components/Footer/Footer";
import axios from "axios";

const url = process.env.REACT_APP_MEDIUM_RSS_JSON;

function App() {
    // theming
    const [isDark, setIsDark] = useState(true);
    let themeValue = 'light';
    const changeTheme = (response) => {
        setIsDark(response);
        themeValue = isDark === true ? 'dark' : 'light';
        localStorage.setItem("theme", themeValue);
        document.documentElement.setAttribute("data-theme", themeValue);
    };

    // medium articles
    const [articleData, setArticleData] = useState(null);
    const getData = async () => {
        await axios.get(url)
            .then((response) => {
                setArticleData(response.data.items)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
            getData();
        },
        [])

    const Home = () => {
        return (
            <div>
                <LandingSection articleData={articleData}/>
            </div>
        );
    };

    return (
        <Router>
            <div className="App" id={themeValue}>
                <NavBar updateParentTheme={changeTheme}/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="cover" element={<CoverLetter/>}/>
                    {/*<Route path="exp" element={<Experience/>}/>*/}
                    <Route path="feed" element={<Feed/>}/>
                    <Route path="feed" element={<Feed/>}/>
                    <Route path="404" element={<Page404 isDarkFromParent={isDark}/>}/>
                    <Route path="/*" element={<Navigate to="404"/>}/>
                </Routes>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;