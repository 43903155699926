import React, {useEffect, useState} from 'react';
import './NavBar.css'
import {Link} from "react-router-dom";
import {BrowserView, MobileView} from 'react-device-detect';


function NavBar({updateParentTheme}) {
    const [isDark, setIsDark] = useState(typeof "boolean");
    let themeValueText = !isDark === true ? 'Switch to Dark mode' : 'Switch to Light mode';

    function changeTheme(res) {
        setIsDark(res)
        updateParentTheme(isDark);
    }

    function checkThemeOnload() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    useEffect(() => {
        const theme = localStorage.getItem('theme');
        if (theme === null) {
            if (checkThemeOnload()) {
                changeTheme(true)
            } else {
                setIsDark(false)
            }
        } else {
            if (theme === 'dark') {
                changeTheme(true)
            } else {
                setIsDark(false)
            }
        }
    }, []);

    return (
        <nav className="navbar navbar-custom">
            <div className="container">
                <Link className="navbar-brand" to={"/"}>
                    {
                        !isDark ?
                            <img className="navbar-brand-img-custom" src={process.env.REACT_APP_LOGO} alt=""/> :
                            <img className="navbar-brand-img-custom" src={process.env.REACT_APP_LOGO_LIGHT} alt=""/>
                    }
                </Link>
                <div className="navbar-link-div">
                    <BrowserView className="nav-bar-browser-view">
                        <Link to={"feed"} className="navbar-link">Writing</Link>
                        <Link to={"watch"} className="navbar-link">Video</Link>
                        <Link to={"about"} className="navbar-link">About</Link>
                        <a href={process.env.REACT_APP_RESUME_LOCAL} className="navbar-link" target="_blank">Resume</a>
                    </BrowserView>
                </div>
                <a href="https://blog.narathota.com/" target="_blank" className="navbar-link">
                    <i className="fab fa-medium-m"></i></a>
                <a href="https://www.linkedin.com/in/narathota/" target="_blank" className="navbar-link">
                    <i className="fab fa-linkedin-in"></i></a>
                <button className="btn btn-link theme-button" onClick={() => changeTheme(!isDark)}
                        title={themeValueText}>
                    {
                        !isDark ?
                            <span>🌙</span> : <span>☀️</span>
                    }
                </button>
                <MobileView className="nav-bar-mobile-view">
                    <div className="navbar-link-div-mobile">
                        <Link to={"feed"} className="navbar-link">Writing</Link>
                        <Link to={"watch"} className="navbar-link">Video</Link>
                        <Link to={"about"} className="navbar-link">About</Link>
                        <a href={process.env.REACT_APP_RESUME_LOCAL} className="navbar-link">Resume</a>
                    </div>
                </MobileView>
            </div>
        </nav>
    );
}

export default NavBar;