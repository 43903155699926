export default [
    {
        src: "/img/background/s1.jpg",
        title: "Image 1",
        description: "Duis aute irure dolor in reprehenderit in voluptate velit",
    },
    {
        src: "/img/background/s2.jpg",
        title: "Image 2",
        description: "Consectetur adipisicing elit cillum dolore eu fugiat nulla",
    },
    {
        src: "/img/background/s3.jpg",
        title: "Image ",
        description: "Asperiores ex animi explicabo cillum dolore eu fugiat nulla",
    },
]