import React, {useEffect, useState} from 'react';
import './CarouselComponent.css';

function CarouselComponent({articleData}) {
    const [currentImage, setCurrentImage] = useState(0)
    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentImage === 2) {
                setCurrentImage(0)
            } else {
                setCurrentImage(currentImage + 1)
            }
        }, 5000)
        return () => clearTimeout(timer)
    }, [currentImage])

    const goToNext = (currentImage) => {
        setCurrentImage(currentImage)
    }

    return (
        <div className="CarouselComponent">
            <div>
                {
                    articleData !== null ?
                        <div>
                            <div className="carousel-div-img">
                                <img className="carousel-img" src={articleData[currentImage].thumbnail} alt="thumbnail image"/>
                            </div>
                            {/*<div className="carousel-div" style={{backgroundImage: `url(${images[currentImage].src})`}}></div>*/}
                            <div className="transparent-overlay"></div>
                            <div className="image-description-div">
                                <div>
                                    <h1 className="image-description-title">{articleData[currentImage].title.substring(0, 40)}...</h1>
                                    <p className="image-description-tags">{articleData[currentImage].categories.toString()}</p>
                                </div>
                                <div className="carousel-button">
                                    {
                                        articleData.map((images, currentImage) => (
                                            <span key={currentImage} onClick={() => goToNext(currentImage)}/>
                                        ))
                                    }
                                </div>
                            </div>

                        </div> : <span/>
                }
            </div>


        </div>
    )
}

CarouselComponent.propTypes = {};

CarouselComponent.defaultProps = {};

export default CarouselComponent;
