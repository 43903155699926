import React from "react";
import WritingArticleComponent from "../components/WritingArticleComponent/WritingArticleComponent";
import images from "../data/images";

const Experience = () => {
    return (
        <div>
            <div className="container">
                {
                    images !== null ?
                        <div>
                            {
                                images.map((images, key) => (

                                    <span key={key}> {images.src} </span>
                                ))
                            }
                        </div> : <span>False</span>
                }
                <div className="col-sm-12 row">
                    <div className="col-sm-6">
                        <WritingArticleComponent data={images[0]}/>
                    </div>
                    <div className="col-sm-6">
                        <WritingArticleComponent data={images[1]}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;
