import React from 'react';
import './WritingArticleComponent.css';

function WritingArticleComponent({data}) {
    return (
        <div className="WritingArticleComponent">
            <div className="card custom-card">
                <img src={data.src} className="card-img-top custom-card-img" alt="..."/>
                <div className="card-body custom-card-body">
                    <h5 className="card-title">{data.title}</h5>
                    <p className="card-text">{data.description}</p>
                    <a href="#" className="btn btn-primary">Go somewhere</a>
                </div>
            </div>
        </div>
    )
}

WritingArticleComponent.propTypes = {};

WritingArticleComponent.defaultProps = {};

export default WritingArticleComponent;
