import {Link} from "react-router-dom";

function Page404({isDarkFromParent}) {
    if (isDarkFromParent === 'string') {
        isDarkFromParent = false;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="container col-md-6 align-self-center">
                    {
                        !isDarkFromParent ?
                            <img className="" src="/img/not-found-dark.svg" alt=""/> :
                            <img className="" src="/img/not-found-light.svg" alt=""/>
                    }
                </div>
                <div className="container col-md-6 align-self-center">
                    <h1 className="page-404-title">404</h1>
                    <h2 className="page-404-sub-title">UH OH! You're lost.</h2>
                    <p className="page-404-body">The page you are looking for does not exist.
                        How you got here is a mystery. But you can click the button below
                        to go back to the homepage.
                    </p>
                    <Link className="btn primary-button" to={"/"}>HOME</Link>
                </div>
            </div>
        </div>
    );
}

export default Page404;
