import React from 'react';
import './LandingSection.css';
import CarouselComponent from "../CarouselComponent/CarouselComponent";

function LandingSection({articleData}) {
    return (
        <div className="LandingSection">
            <CarouselComponent articleData={articleData}/>
        </div>
    )
}

LandingSection.propTypes = {};

LandingSection.defaultProps = {};

export default LandingSection;
