import React from "react";
import AboutComponent from "../components/AboutComponent/AboutComponent";

const About = () => {
    return (
        <div className="container">
            <AboutComponent/>
        </div>
    );
};

export default About;
