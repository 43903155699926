import React from "react";
// import CoverLetterComp from "../components/CoverLetterComp/CoverLetterComp";

const CoverLetter = () => {
    return (
        <div className="container">
            {/*<CoverLetterComp/>*/}
        </div>
    );
};

export default CoverLetter;
